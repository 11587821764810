<template>
  <div class="login">
    <div class="login_main">
      <div class="main_left">
        <span class="title">登录</span>
        <el-input
          prefix-icon="el-icon-user-solid"
          v-trim
          v-model="username"
          clearable
        ></el-input>
        <el-input
          prefix-icon="el-icon-lock"
          v-trim
          v-model="password"
          :type="passw"
        >
          <i slot="suffix" :class="icon" @click="showPass"></i>
        </el-input>
        <el-button @click="login" type="primary">登录</el-button>
        <!-- <span class="btm_label">怡成健康</span> -->
      </div>
      <div class="main_right">
        <img class="main_right_img" src="../assets/image/loginPic.png" alt="" />
      </div>
    </div>
    <div class="login_bottom">
      Copyright © 北京腾焰软件有限公司 <br />
      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
        target="_blank"
        href="https://beian.miit.gov.cn/"
        >京ICP备11023466号-6</a
      >
    </div>
  </div>
</template>

<script>
import layout from "@/views/Layout/index";
import BlankPage from "@/views/Layout/blankPage";
import admin from "@/router/otcHealth/admin.js"; //怡成端
import chain from "@/router/otcHealth/chain.js"; //连锁端
import region from "@/router/otcHealth/region.js"; //大区端
import area from "@/router/otcHealth/area.js"; //片区端
import pharmacy from "@/router/otcHealth/pharmacy.js"; //片区端
import { setToke } from "@/http/request.js";

export default {
  data() {
    return {
      //用于改变Input类型
      passw: "password",
      username: "",
      password: "",
      isRolad: false,
      icon: "el-input__icon el-icon-view"
    };
  },
  created() {
    // if (this.$store.state.login.addRoutes.length > 0) {
    //   return window.location.reload();
    // }
    this.isRolad = true;

    let _self = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      // console.log(_self.$route);
      if (_self.$route.path == "/login" && key == 13) {
        _self.login();
      }
    };
  },

  mounted() {
    sessionStorage.clear();
  },
  watch: {
    username(val) {
      // console.log(val);
    }
  },
  methods: {
    //密码的隐藏和显示
    showPass() {
      //点击图标是密码隐藏或显示
      if (this.passw == "text") {
        this.passw = "password";
        //更换图标
        this.icon = "el-input__icon el-icon-view";
      } else {
        this.passw = "text";
        this.icon = "el-input__icon el-icon-loading";
      }
    },

    blankPage(router, sign) {
      // console.log(router, sign);
      // sign为了防止路由第一层layout(主页)设置为BlankPage(空白页);
      sign++;
      router.forEach((item) => {
        // console.log(item);
        if (item.children && item.children.length > 0) {
          if (sign > 1) {
            item.component = "BlankPage";
          }
          this.blankPage(item.children, sign);
        }
      });
    },
    // 将后端传回的component 的字符串模式，改为我们前端路由需要的component模式
    loadPageByRoutes(str) {
      // console.log(str);
      return function (resolve) {
        require([`@/views${str}`], resolve);
      };
      // return (resolve) => require([`@/views/${str}`], resolve);
    },
    // 递归路由表
    getAddRoutes(rote) {
      // console.log(rote);
      rote.forEach((item) => {
        if (item.component == "layout") {
          item.component = layout;
        } else if (item.component == "BlankPage") {
          item.component = BlankPage;
        } else {
          item.component = this.loadPageByRoutes(item.component);
        }

        if (item.children && item.children.length > 0) {
          this.getAddRoutes(item.children);
        }
      });
    },
    async login() {
      let data = {
        account: this.username,
        pwd: this.password
      };
      // console.log(data);

      this.$api.login
        .loginByPassword(data)
        .then(async (res) => {
          // console.log(res);
          sessionStorage.setItem("userType", res.data.admin.adminType);
          sessionStorage.setItem("userId", res.data.admin.id);
          sessionStorage.setItem("userAccount", res.data.admin.adminAccount);
          const userInfo = {
            type: res.data.admin.adminType,
            id: res.data.admin.id,
            account: res.data.admin.adminAccount,
            adminYichengId: res.data.admin.adminYichengId,
            adminRegionId: res.data.admin.adminRegionId
          };
          // 缓存用户公共信息
          this.$store.dispatch("login/user_info", userInfo);
          // 设置token
          this.$store.dispatch("login/user_token", res.data.token);
          // 本地路由记载方式 --测试
          let addRoutes = [];
          if (res.data.admin.adminType == "yicheng") {
            addRoutes = admin;
          } else if (res.data.admin.adminType == "chain") {
            if (res.data.admin.adminChainCdzName != null) {
              chain.forEach((item) => {
                item.children.forEach((itm) => {
                  // 知识库
                  if (itm.path == "/knowledgeBaseDetail") {
                    itm.meta.title = res.data.admin.adminChainKbName;
                    // 慢病管理
                  } else if (itm.path == "/chronicDiseaseDetail") {
                    itm.meta.title = res.data.admin.adminChainCdzName;
                  }
                  // console.log(itm);
                });
              });
            }
            addRoutes = chain;
          } else if (res.data.admin.adminType == "region") {
            addRoutes = region;
          } else if (res.data.admin.adminType == "area") {
            addRoutes = area;
          } else if (res.data.admin.adminType == "pharmacy") {
            addRoutes = pharmacy;
          }
          //   addRoutes = mechanism;
          // } else if (res.data.userType == "coupon_zd") {
          //   addRoutes = generalAgent;
          // } else if (res.data.userType == "coupon_dl") {
          //   addRoutes = agent;
          // }
          // console.log(addRoutes);
          this.$store.dispatch("login/user_information", addRoutes);
          let resultsRoutes = sessionStorage.getItem("addRoutes")
            ? JSON.parse(sessionStorage.getItem("addRoutes"))
            : [];
          await this.blankPage(resultsRoutes, 0);
          await this.getAddRoutes(resultsRoutes);
          // console.log(resultsRoutes);
          resultsRoutes.forEach((item) => {
            // console.log(item);
            this.$router.addRoute(item);
          });

          let toPathJson = sessionStorage.getItem("addRoutes");
          // console.log(toPathJson);
          let toPath = toPathJson ? JSON.parse(toPathJson) : [];
          // console.log(toPath);
          let topath = toPath.length > 0 ? toPath[0].path : "";
          // console.log(topath);
          if (!topath) {
            return this.$message.error("该账号没有访问权限!!!");
          }
          // console.log(topath);
          this.$router.push({ path: topath });
          // this.$message.success(res.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });

      //解决登录成功后未刷新状态token生效
      await setToke();
    }
  }
};
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  background: url(../assets/image/1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 15px;
  .login_bottom {
    position: absolute;
    bottom: 20px;
    color: gray;
    a:visited {
      color: gray;
    }
  }
  .login_main {
    z-index: 99;
    width: 60%;
    height: 70%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;
    // padding: 0 80px;
    .main_right {
      max-width: 80%;
      height: 100%;
      // background-color: red;
      background: url(../assets/image/bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .main_right_img {
        max-width: 50%;
      }
    }
    .main_left {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-left: 40px;
      // max-width: 400px;
      .title {
        font-family: Poppins-Bold;
        font-size: 24px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        padding-bottom: 54px;
      }
      .el-input {
        margin-bottom: 10px;
        line-height: 50px;
        height: 50px;
        max-width: 300px;
        .el-input__inner {
          height: 50px;
          line-height: 50px;
          border-radius: 2rem;
          font-size: 17px;
          padding-left: 40px;
        }
        .el-input__prefix {
          color: #767677;
          font-size: 20px;
          height: 50px;
          left: 15px;
        }
      }
      .el-button {
        min-width: 300px;
        margin-top: 20px;
      }
      .btm_label {
        display: block;
        margin-top: 136px;
        // background: #c850c0;
        font-size: 13px;
        color: #666666;
      }
    }
  }
  @media screen and (max-width: 770px) {
    .login_main {
      display: flex;
      // background: #c850c0;
      justify-content: center;
      align-items: center;
      .main_left {
        .el-input {
          margin-bottom: 10px;
          line-height: 50px;
          height: 50px;
          max-width: 240px;
          .el-input__inner {
            height: 50px;
            line-height: 50px;
            border-radius: 2rem;
            font-size: 17px;
            padding-left: 40px;
          }
          .el-input__prefix {
            color: #767677;
            font-size: 20px;
            height: 50px;
            left: 15px;
          }
        }
        .el-button {
          min-width: 240px;
          margin-top: 20px;
        }
      }
      .main_right {
        display: none;
      }
    }
  }
}
</style>
